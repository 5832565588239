<script setup>
import { ref, computed, defineEmits } from "vue";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
const emit = defineEmits(["save"]);
const { t } = useI18n();
const store = useStore();
store.dispatch("loadUsers");
const users = computed(() => store.getters.activeUsers);
const comment = ref("");
const save = () => {
  const task = {
    comment: comment.value,
    users: users.value.filter((user) => user.task).map((user) => user.id),
  };
  // store.dispatch("addTask", task);
  emit("save", task);
};
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h3 class="text-center my-3">{{ t("general.task_add") }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="comment">{{ t("general.comment") }}</label>
          <textarea class="form-control task-comment" id="comment" rows="3" v-model="comment"></textarea>
        </div>
      </div>
      <div class="col-md-6">
        <span>{{ t("user.user") }}</span>
        <div class="user-select border rounded p-2">
          <div v-for="user in users" :key="user.id" class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" v-model="user.task" :id="'user_' + user.id" />
            <label class="form-check-label" :for="'user_' + user.id">{{ user.name }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center my-3">
        <button @click="save" type="button" class="btn btn-primary">{{ t("general.save") }}</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.task-comment {
  height: 200px;
}
.user-select {
  height: 200px;
  overflow-y: auto;
}
</style>
