<template>
  <Modal v-if="searchActive" @close="close" :modalActive="searchActive" modalCssClass="modal-fullscreen">
    <template v-slot:header>{{ t("general.link_stage") }}</template>
    <template v-slot:body>
      <div v-if="loading" class="d-flex flex-column justify-content-center align-items-center w-100">
        <div class="spinner-border text-light global-loader" role="status"></div>
      </div>
      <div v-if="!loading && stages.length > 0" class="card-group gws_card-group">
        <div v-for="stage in stages" :key="stage.id" class="card gws_search_results mb-sm-2 text-dark me-2">
          <div class="card-body">
            <StageCardText :stage="stage">
              <template v-slot:cardaction>
                <button
                  v-if="stage.id"
                  @click="$emit('setStage', stage.id)"
                  class="btn btn-success btn-icon me-2"
                  :title="t('general.link')"
                >
                  <svg class="bi" width="20" height="20" fill="currentColor">
                    <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                  </svg>
                  {{ t("general.link") }}
                </button>
              </template>
            </StageCardText>
          </div>
        </div>
      </div>
      <div
        v-if="!loading && stages.length === 0"
        class="d-flex flex-column justify-content-center align-items-center w-100"
      >
        <p class="text-light">{{ t("stage.no_stages") }}</p>
      </div>
    </template>
    <template v-slot:footer>
      <button @click="close" type="button" class="btn btn-primary">{{ t("general.cancel") }}</button>
    </template>
  </Modal>
</template>

<script setup>
import { defineProps, defineEmits, watch, ref } from "vue";
import Modal from "@/components/Modal.vue";
import { useI18n } from "vue-i18n/index";
import { useApiQuery } from "@/composables/useApiQueries";
import StageCardText from "@/components/stages/CardText.vue";
const { t } = useI18n();
const props = defineProps({
  searchActive: {
    type: Boolean,
    default: false,
  },
  venueId: {
    type: Number,
    default: 0,
  },
});
const emit = defineEmits(["close", "setStage"]);
const close = () => {
  emit("close");
};
// get stages
const { result: stages, query: getStages } = useApiQuery({
  url: "search/stages/byvenue",
  showLoading: false,
});
const loading = ref(false);
watch(
  () => props.venueId,
  async (newVal) => {
    if (newVal) {
      stages.value = [];
      loading.value = true;
      await getStages({
        params: {
          params: {
            venue_id: newVal,
          },
        },
      });
      loading.value = false;
    }
  }
);
</script>
